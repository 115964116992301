import { HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/common";
export class AuthService {
    constructor(router, http, location) {
        this.router = router;
        this.http = http;
        this.location = location;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': environment.api.contentType
            })
        };
        this.secret = 'sQLqlmMCftL93ES3';
        this.redirectURL = undefined;
        this.showLoginButton = new BehaviorSubject(true);
        this.showLoginButton$ = this.showLoginButton.asObservable();
        this.user$ = new BehaviorSubject(this.restore());
        this.userObservable = this.user$.asObservable();
    }
    doPostLogin(userData) {
        let dto = {
            eta: userData.eta,
            sesso: userData.sesso,
            titoloStudio: userData.titoloStudio,
            luogo: userData.luogo,
            enteProvenienza: userData.enteProvenienza,
            usernamealt: userData.usernamealt
        };
        return this.http.post(environment.api.censimentoDatiUtente.endpoint, dto, this.httpOptions).pipe(map(u => {
            const myUser = Object.assign({ username: u.user, role: u.userRole }, u);
            this.user$.next(myUser);
            localStorage.setItem('user', this.encDec(JSON.stringify(myUser)));
            // // console.log('[AuthService][doPostLogin] user',myUser)
            this.showLoginButton.next(false);
            return myUser;
        }));
    }
    displayLoginButton(b) {
        this.showLoginButton.next(b);
    }
    login(username, password) {
        return this.http.post(environment.api.censimentoUtente.endpoint, { user: "Anonimo" }, this.httpOptions).pipe(map(u => {
            const myUser = Object.assign({ username: u.user, role: u.userRole }, u);
            this.user$.next(myUser);
            localStorage.setItem('user', this.encDec(JSON.stringify(myUser)));
            // if (u.nuovo) // console.log('[AuthImplServ][login] nuovo utente')
            // if (u.user === "Anonimo") // console.log('[AuthImplServ][login] utente anonimo')
            return myUser;
        }));
    }
    checkPostLogin() {
        const currentUser = this.currentUser;
        let allUserFieldsPopulated = false;
        if (currentUser)
            allUserFieldsPopulated = Object.values(currentUser).every(v => v !== undefined && v !== '' && v !== null);
        const url = this.location.path();
        if (currentUser && allUserFieldsPopulated) {
            return true;
        }
        if (!url.includes('auth/login')) {
            this.router.navigate(['auth/login'], { queryParams: { returnUrl: url } });
        }
        else {
            this.router.navigate(['auth/login']);
        }
        return false;
    }
    get currentUser() {
        return this.user$.value;
    }
    logout() {
        localStorage.removeItem('user');
        this.deleteAllCookies();
        this.user$.next(undefined);
        window.location.reload();
    }
    deleteAllCookies() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
    restore() {
        const userString = localStorage.getItem('user');
        if (userString) {
            // // console.info('[AuthService Restore] restoring user session', userString)
            // console.info('[AuthService Restore] restoring user session')
            const dec = this.encDec(userString, true);
            if (dec !== "")
                return JSON.parse(dec);
            else
                return undefined;
        }
        return undefined;
    }
    getUser() {
        return this.userObservable;
    }
    encDec(text, dec) {
        if (dec) {
            try {
                return CryptoJS.AES.decrypt(text, this.secret).toString(CryptoJS.enc.Utf8);
            }
            catch (error) {
                localStorage.removeItem('user');
                return "";
            }
        }
        else {
            return CryptoJS.AES.encrypt(text, this.secret).toString();
        }
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Location)); }, token: AuthService, providedIn: "root" });
