import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class TogglePaneService {
    constructor() {
        this.isOpen$ = new BehaviorSubject(true);
        this.isOpen = this.isOpen$.asObservable();
    }
    toggle() {
        this.isOpen$.next(!this.isOpen$.value);
    }
    forceOpen() {
        this.isOpen$.next(true);
    }
    open() {
        this.isOpen$.next(true);
    }
    close() {
        this.isOpen$.next(false);
    }
}
TogglePaneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TogglePaneService_Factory() { return new TogglePaneService(); }, token: TogglePaneService, providedIn: "root" });
