// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AuthConfig } from "angular-oauth2-oidc";

export const environment = {
  production: true,
  api: {
    contentType: 'application/json',
    endpointPrefixes: ["formaromae-txt-api", "formaromae-geo-api", "formaromae-img-api", "formaromae-recommandation-api", "formaromae-profilazione"],
    textSearch: {
      basic: {
        endpoint: 'formaromae-txt-api/textSearch',
        method: 'POST'
      },
      advanced: {
        endpoint: 'formaromae-txt-api/advancedTextSearch',
        method: 'POST'
      }
    },
    geoSearch: {
      polygon: {
        endpoint: 'formaromae-geo-api/rest/geografies/geoSearch',
        method: 'POST',
      },
      circle: {
        endpoint: 'formaromae-geo-api/rest/geografies/geoSearchByCircle',
        method: 'POST',
      },
      detail: {
        endpoint: 'formaromae-geo-api/rest/geografies/geoSearchDetail',
        method: 'POST',
      }
    },
    archives: {
      endpoint: 'formaromae-geo-api/rest/utils/databases',
      method: 'POST'
    },
    maps: {
      endpoint: 'formaromae-geo-api/rest/layers/getMaps',
      method: 'POST'
    },
    categories: {
      endpoint: 'formaromae-geo-api/rest/utils/categorie',
      method: 'POST'
    },
    tipologyImage: {
      endpoint: 'formaromae-img-api/image-api/imageSearch/tipologia',
      method: 'POST'
    },
    fullImage: {
      endpoint: 'formaromae-img-api/image-api/getFullImgByPath',
      method: 'POST'
    },
    pointClouds: {
      endpoint: 'formaromae-geo-api/rest/pointclouds/search',
      method: 'POST'
    },
    imageFromPath: {
      endpoint: 'formaromae-img-api/image-api',
      method: 'POST'
    },
    imageSearch: {
      endpoint: 'formaromae-txt-api/imageSearch',
      method: 'POST'
    },
    imageByRelativePath: {
      endpoint: 'formaromae-img-api/image-api/getFullImgByRelativePath',
      method: 'POST'
    },
    censimentoUtente: {
      endpoint: 'formaromae-profilazione/profilazione/censimentoUtente',
      method: 'POST'
    },
    censimentoDatiUtente: {
      endpoint: 'formaromae-profilazione/profilazione/censimentoDatiUtente',
      method: 'POST'
    },
    getRaccomandations: {
      endpoint: 'formaromae-recommandation-api/api/lettura',
      method: 'POST'
    },
    getUserRaccomandations: {
      endpoint: 'formaromae-recommandation-api/api/letturaRicercheUtente',
      method: 'POST'
    },
    getLayersByMap: {
      endpoint: 'formaromae-geo-api/rest/layers/getLayersByMap',
      method: 'POST'
    },
    getLayerById: {
      endpoint: 'formaromae-geo-api/rest/layers/getLayerById',
      method: 'POST'
    },
    getLayersTreeByMap: {
      endpoint: 'formaromae-geo-api/rest/layers/getLayersTreeByMap',
      method: 'POST'
    },
    traceLayerUse: {
      endpoint: 'formaromae-geo-api/rest/layers/traceLayerUse',
      method: 'POST'
    },
    traceImageUse: {
      endpoint: 'formaromae-img-api/image-api/traceImageUse',
      method: 'POST'
    }
  },
  sso: {
    baseSSOUri: "https://ssopre.comune.roma.it",
    config: {
      // Url of the Identity Provider
      issuer: 'https://ssopre.comune.roma.it:443/ssoservice/oauth2/realms/root/realms/public',
      requireHttps: false,
      redirectUri: "https://formaromae-preprod.comune.roma.it/",
      clientId: 'formaromae',
      responseType: 'code',
      scope: 'openid tipo_utente',
      dummyClientSecret: 'Formaromae.1',
      skipIssuerCheck: true,
      showDebugInformation: false,
      strictDiscoveryDocumentValidation: false,
           clearHashAfterLogin: true
    } as AuthConfig
  },

  gamificationURL: 'https://formaromae-preprod.comune.roma.it/formaromaeGam/',
  gamificationPublicURL: 'https://formaromae-preprod.comune.roma.it/formaromaeGam/',
  apiCatalogURL: 'https://formaromae-preprod.comune.roma.it/formaromaeGam/catalogo-api',
  apiCatalogPublicURL: 'https://formaromae-preprod.comune.roma.it/formaromaeGam/catalogo-api',
  //Definisce cosa passare a backend per ottenere / ricercare immagini: percorso completo (true) oppure solo nome file (false)
  fullpathForImages: true,
  //Abilita (true) disabilita (false) la visualizazione della pagina di postLogin ( raccolta dati )
  postLoginEnabled: true,
  //Seleziona l'id del webGIS di default, qualora l'utente effettui direttamente una ricerca dalla home page. Necessario che sia string
  defaultWebGISId: '30',
  suggerimentiWebGISId: '32',
  //Paginazione / infinite scroll immagini per tipologia. Numero di immagini da caricare
  maxImagesLoadedForTypology: 9,
  //Stringa da visualizzare qualora il valore per la chiave selezionata non fosse disponibile (scheda risultati)
  informazioneNonDisponibile: 'Informazione non disponibile',
  //Paginazione / infinite scroll immagini scheda dettaglio. Numero di immagini da caricare
  imagesPerPageInDetailPage: 4,
  //limita l'area di ricerca (km^2) -1 per non limitare
  geoSearchLimitArea: -1,
  //timeout in millisecondi per le richieste http
  httpReqTimeout: 120000,
  //testo dei tooltip
  tooltip: {
    //millisecondi
    chiudiDopo: 450,
    strumentiMappa: {
      ricercaGeoCerchio: "Ricerca circolare",
      ricercaGeoPoligono: "Ricerca poligonale",
      ricercaGeoAnnulla: "Annulla",
      bottoneInfo: "Info",
      bottonePDF: "Scarica in PDF",
      misuraLunghezza: "Misura lunghezza",
      misuraSuperficie: "Misura superficie",
      annullaMisura: "Annulla",
      selettoreGIS: "Seleziona webGIS",
      tematismi: "Seleziona tematismi",
      collassaRiquadroLaterale: "Collassa riquadro",
      ripristinaRiquadroLaterale: "Ripristina riquadro"
    },
    ricercaTestuale: "Ricerca testuale",
    ricercaAvanzata: "Ricerca avanzata",
    dettagliLinkArchivio: "Visualizza il dettaglio nell’Archivio di appartenenza"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
