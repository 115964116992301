import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private visible$ = new BehaviorSubject<boolean>(false)

  constructor() {
  }

  public show() {
    // // console.log('[SpinnerService] Showing Spinner')
    this.visible$.next(true)
  }

  public hide() {
    // // console.log('[SpinnerService] Hiding Spinner')
    this.visible$.next(false)
  }

  public isVisible():BehaviorSubject<boolean> {
    return this.visible$
  }


}
