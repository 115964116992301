import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LogoutPageComponent } from './components/pages/logout-page/logout-page.component';
import { ModalComponent } from './components/modal/modal.component';
import { NgbModule, NgbModal, NgbModalModule, NgbCollapseModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap'
import { IPlacesService } from './services/iplaces.service';
import { RouterModule } from '@angular/router';
import { PlacesService } from './services/impl/places.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ShowMoreModalComponent } from './components/show-more-modal/show-more-modal.component';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import {TranslateModule} from '@ngx-translate/core';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};
@NgModule({
  declarations: [NavbarComponent, FooterComponent, HeaderComponent, LogoutComponent, LogoutPageComponent, ModalComponent, SpinnerComponent, ShowMoreModalComponent, StarRatingComponent, ScrollToDirective],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbCollapseModule,
    RouterModule,
    NgbModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    DeferLoadModule,
    TranslateModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    StarRatingComponent,
    NgbModule,
    SpinnerComponent,
    ScrollToDirective,
    FormsModule,
    ReactiveFormsModule,
    DeferLoadModule,
    TranslateModule
  ],
  providers: [
    // NgbModule,
    // {
    //   provide: IPlacesService,
    //   useClass: PlacesService
    // },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [ModalComponent, ShowMoreModalComponent]
})
export class SharedModule { }
