import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAuthService } from 'src/app/core/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { PointCloud } from '../models/pointCloud';
import { User } from 'src/app/core/models/user';
import { environment } from 'src/environments/environment';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PointCloudService {
  private clouds$ = new BehaviorSubject<PointCloud[]>(undefined)
  private clouds = this.clouds$.asObservable()
  private user: User
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': environment.api.contentType
    })
  }
  constructor(
    private http: HttpClient,
    private authService: IAuthService
  ) {
    this.authService.getUser().subscribe(user => {if (user) this.user=user; this.setClouds().subscribe(clouds => this.clouds$.next(clouds))})
  }

  setClouds():Observable<PointCloud[]>{
    // console.log('[PointCloudService] fetching point clouds...')
    let dto = { user: this.user.name }
    return this.http.post<PointCloud[]>(environment.api.pointClouds.endpoint,dto,this.httpOptions).pipe(
      map(clouds =>  (clouds))
    )
  }

  getClouds():Observable<PointCloud[]> {
    return this.clouds
  }

  getCloudById(resourceId:string):PointCloud{
    if (this.clouds$.value) return this.clouds$.value.find(c => c.resourceId == resourceId)
    else this.setClouds().pipe(
      map(clouds => (clouds.find(c => c.resourceId == resourceId))),
      first(c => c !== undefined)
    )
  }
}
