import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/services/auth.service";
export class PointCloudService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.clouds$ = new BehaviorSubject(undefined);
        this.clouds = this.clouds$.asObservable();
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': environment.api.contentType
            })
        };
        this.authService.getUser().subscribe(user => { if (user)
            this.user = user; this.setClouds().subscribe(clouds => this.clouds$.next(clouds)); });
    }
    setClouds() {
        // console.log('[PointCloudService] fetching point clouds...')
        let dto = { user: this.user.name };
        return this.http.post(environment.api.pointClouds.endpoint, dto, this.httpOptions).pipe(map(clouds => (clouds)));
    }
    getClouds() {
        return this.clouds;
    }
    getCloudById(resourceId) {
        if (this.clouds$.value)
            return this.clouds$.value.find(c => c.resourceId == resourceId);
        else
            this.setClouds().pipe(map(clouds => (clouds.find(c => c.resourceId == resourceId))), first(c => c !== undefined));
    }
}
PointCloudService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PointCloudService_Factory() { return new PointCloudService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.IAuthService)); }, token: PointCloudService, providedIn: "root" });
