import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user';
import { IAuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from "@ngx-translate/core";
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  currentURL: string = '';
  currentLang: string;
  urls: any = {
    home: 'CONTENT/HOME',
    project: 'CONTENT/PROJECT',
    archives: 'CONTENT/ARCHIVES',
    login: 'AUTH/LOGIN',
    logout: 'LOGOUT',
    pointCloud: 'CONTENT/POINTCLOUD',
    apiCatalog: environment.apiCatalogURL.toUpperCase(),
    gamification: environment.gamificationURL.toUpperCase()
  }
  // loginUrl = environment.externalLoginURL
  apiCatalogURL = environment.apiCatalogURL
  apiCatalogPublicURL = environment.apiCatalogPublicURL
  gamificationURL = environment.gamificationURL
  gamificationPublicURL = environment.gamificationPublicURL
  authenticated: boolean
  user: User;
  constructor(private router: Router, private authService: IAuthService, private translate: TranslateService, private oauthService: OAuthService) {
    this.router.events.subscribe((url: any) => {
      if (url.url) {
        this.currentURL = url.url.substr(1).toUpperCase()
      }
    })
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.user = user
      }
    })
  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
  }

  // public isActiveUrl(url): boolean {
  //   return this.currentURL
  // }

  isLoggedIn() {
    return this.user && this.user.username && this.user.username != "Anonimo";
  }

  logout() {
    
    this.oauthService.logOut();
    this.authService.logout();
  }

  login() {
    this.oauthService.initCodeFlow();

  }

  setLanguage(lang: string) {
    localStorage.setItem("locale", lang);
    this.translate.use(lang).subscribe(() => {
      this.currentLang = this.translate.currentLang;
      window.location.reload();
    });
  }
}
