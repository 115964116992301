import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class IAuthService {
  public userObservable:Observable<User>
  public showLoginButton$: Observable<boolean>
  public redirectURL: string
  public abstract get currentUser():User
  abstract login(u?:string,p?:string): Observable<User>
  abstract logout():boolean | void
  abstract restore():void | User
  abstract getUser(): Observable<User>
  abstract displayLoginButton(b:boolean): void
  abstract checkPostLogin(): boolean
  abstract doPostLogin(userData: any): Observable<User>
}
