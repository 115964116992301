<div *ngIf="isVisible | async">
    <div class="blur"></div>
    <div id="overlay">
        <div class="container-fluid vertically-centered">
            <div class="row m-0 justify-content-center align-items-center">
                <div class="progress-spinner progress-spinner-double progress-spinner-active">
                    <div class="progress-spinner-inner"></div>
                    <!-- <div class="progress-spinner-inner"></div> -->
                </div>
            </div>
            <div class="row m-0 mt-2 justify-content-center align-items-center font-weight-bold" style="font-size: 32px; color: #8E001C;">
              {{'SPINNER.CARICAMENTO' | translate}}
            </div>
        </div>
    </div>
</div>
