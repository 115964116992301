import { Injectable } from '@angular/core';
import { IAuthService } from '../auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class AuthService implements IAuthService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': environment.api.contentType
    })
  }
  private secret: string = 'sQLqlmMCftL93ES3';
  public redirectURL: string = undefined
  private user$: BehaviorSubject<User>
  public userObservable: Observable<User>
  private showLoginButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  public showLoginButton$: Observable<boolean> = this.showLoginButton.asObservable()

  constructor(private router: Router, private http: HttpClient, private location: Location) {
    this.user$ = new BehaviorSubject<User>(this.restore())
    this.userObservable = this.user$.asObservable()
  }


  doPostLogin(userData: any): Observable<User> {
    let dto = {
      eta: userData.eta,
      sesso: userData.sesso,
      titoloStudio: userData.titoloStudio,
      luogo: userData.luogo,
      enteProvenienza: userData.enteProvenienza,
      usernamealt: userData.usernamealt
    }
    return this.http.post<User>(environment.api.censimentoDatiUtente.endpoint, dto, this.httpOptions).pipe(
      map(u => {
        const myUser: User = {
          username: u.user,
          role: u.userRole,
          // nuovo: false,
          ...u
        }
        this.user$.next(myUser)
        localStorage.setItem('user', this.encDec(JSON.stringify(myUser)));
        // // console.log('[AuthService][doPostLogin] user',myUser)
        this.showLoginButton.next(false)
        return myUser
      })
    )
  }

  displayLoginButton(b: boolean) {
    this.showLoginButton.next(b)
  }

  login(username?: string, password?: string): Observable<User> {
    return this.http.post<User>(environment.api.censimentoUtente.endpoint, { user: "Anonimo" }, this.httpOptions).pipe(
      map(u => {
        const myUser: User = {
          username: u.user,
          role: u.userRole,
          ...u,
          // nuovo: true
        }

        this.user$.next(myUser)
        localStorage.setItem('user', this.encDec(JSON.stringify(myUser)));
        // if (u.nuovo) // console.log('[AuthImplServ][login] nuovo utente')
        // if (u.user === "Anonimo") // console.log('[AuthImplServ][login] utente anonimo')
        return myUser
      })
    )
  }

  checkPostLogin(): boolean {
    const currentUser: User = this.currentUser;
    let allUserFieldsPopulated = false
    if (currentUser) allUserFieldsPopulated = Object.values(currentUser).every(v => v !== undefined && v !== '' && v !== null);
    const url: string = this.location.path()
    if (currentUser && allUserFieldsPopulated) {
      return true;
    }
    if (!url.includes('auth/login')) {
      this.router.navigate(['auth/login'], { queryParams: { returnUrl: url } });
    } else {
      this.router.navigate(['auth/login']);
    }
    return false;
  }

  public get currentUser(): User {
    return this.user$.value
  }

  logout(): boolean | void {
    localStorage.removeItem('user')
    this.deleteAllCookies();
    this.user$.next(undefined)
    window.location.reload();
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  restore(): User {
    const userString = localStorage.getItem('user')
    if (userString) {
      // // console.info('[AuthService Restore] restoring user session', userString)
      // console.info('[AuthService Restore] restoring user session')
      const dec = this.encDec(userString, true)
      if (dec !== "") return JSON.parse(dec)
      else return undefined
    }
    return undefined
  }

  getUser(): Observable<User> {
    return this.userObservable
  }

  private encDec(text: string, dec?: boolean): string {
    if (dec) {
      try {
        return CryptoJS.AES.decrypt(text, this.secret).toString(CryptoJS.enc.Utf8);
      } catch (error) {
        localStorage.removeItem('user')
        return ""
      }

    } else {
      return CryptoJS.AES.encrypt(text, this.secret).toString();

    }
  }
}
