import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LogoutPageComponent } from './shared/components/pages/logout-page/logout-page.component';
const ɵ0 = () => import("./modules/auth/auth.module.ngfactory").then(mod => mod.AuthModuleNgFactory), ɵ1 = () => import("./modules/content/content.module.ngfactory").then(mod => mod.ContentModuleNgFactory);
const routes = [
    { path: 'auth', loadChildren: ɵ0 },
    { path: 'content', loadChildren: ɵ1, canLoad: [AuthGuard], canActivate: [AuthGuard] },
    { path: 'logout', canActivate: [AuthGuard], component: LogoutPageComponent },
    { path: '', redirectTo: 'content/home', pathMatch: 'full' },
    { path: '**', redirectTo: '/content/home', pathMatch: 'full' }
];
// const routes: Routes = []
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
