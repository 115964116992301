<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{config.title}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <svg class="icon">
            <use xlink:href="./assets/svg/sprite.svg#it-close"></use>
            </svg>
        </button>
    </div>
    <div class="modal-body" *ngIf="config.text">
        <p>{{config.text}}</p>

        <form [formGroup]="optionsForm" class="form-group">
            <div class="form-row">

                <label for="dims" style="position: relative; width:100%;">{{'MODAL.DIMENSIONE' | translate}}
                    <select id="dims" class="form-control" formControlName="dimensionsSelect" (change)="onDimensionChange()">
                        <option [ngValue]="dimension.key" *ngFor="let dimension of dimensions | keyvalue">{{dimension.key}}</option>
                    </select>
                </label>

            </div>
            <div class="form-row">
                <label for="res" style="position: relative; width:100%;">{{'MODAL.RISOLUZIONE' | translate}}
                    <select id="res" class="form-control" formControlName="resolutionSelect" (change)="onResolutionChange()">
                        <option [ngValue]="res" *ngFor="let res of resolutions">{{res}}&nbsp;DPI</option>
                    </select>
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">{{config.closeText? config.closeText : defaultCloseText}}</button>
        <button type="button" *ngIf="action" class="btn btn-success" (click)="save()">{{config.actionText}}</button>
    </div>
</div>
