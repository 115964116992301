import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TogglePaneService {

  private isOpen$ = new BehaviorSubject<boolean>(true)
  public isOpen = this.isOpen$.asObservable()
  constructor() { }

  public toggle(){
    this.isOpen$.next(!this.isOpen$.value)
  }
  public forceOpen() {
    this.isOpen$.next(true)
  }

  public open(){
    this.isOpen$.next(true)
  }

  public close(){
    this.isOpen$.next(false)
  }

}
