import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { IAuthService } from './core/services/auth.service';
import { AuthService } from './core/services/impl/auth.impl.service';
import { AuthGuard } from './core/guards/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { SpinnerService } from './shared/services/spinner.service';
import { MyHttpInterceptor } from './shared/services/http.interceptor';
import { PointCloudService } from './shared/services/point-cloud.service';
import { IPlacesService } from './shared/services/iplaces.service';
import { PlacesService } from './shared/services/impl/places.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', ".json");
}

export function getResourceServerUrls() {
  return environment.api.endpointPrefixes;
}

export function storageFactory() : OAuthStorage {
  return localStorage
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'it',

    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: getResourceServerUrls(),
        sendAccessToken: true
      }
    })
  ],
  providers: [
    {
      provide: IAuthService,
      useClass: AuthService
    },
    {
      provide: IPlacesService,
      useClass: PlacesService
    },
    SpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    AuthGuard,
    PointCloudService
  ],
  // exports: [
  //   BrowserModule,
  //   BrowserAnimationsModule,
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
