<div class="d-flex flex-row" style="width: 100%">
  <footer class="it-footer" style="width:100%">
    <div class="it-footer-main pb-2">
      <div class="container">
        <section>
          <div class="row m-0 d-none d-lg-flex align-items-center" style="padding: 32px 0px">
            <div class="col-3 align-items-center">
              <img src="assets/img/logo_roma_sm.png" class="img-border">
            </div>
            <div class="col-3 align-items-center">
              <img src="assets/img/logoPonMetroColor.png" class="img-border">
            </div>
            <div class="col-3 align-items-center">
              <img src="assets/img/logoRepubblica.png" class="img-border">
            </div>
            <div class="col-3 align-items-center">
              <img src="assets/img/logoEUW.png" class="img-no-border">
            </div>
          </div>

          <div class="col-xs-12 d-xs-flex d-lg-none">
            <img src="assets/img/logo_roma_sm.png" class="img-no-border">
            <img src="assets/img/logoPonMetroColor.png" class="img-no-border">
            <img src="assets/img/logoRepubblica.png" class="img-no-border">
            <img src="assets/img/logoEUW.png" class="img-no-border">
          </div>
        </section>
      </div>
    </div>

    <div class="it-footer-small-prints clearfix">
      <div class="container border-white border-top">
        <h3 class="sr-only">Sezione Link Utili</h3>
        <ul class="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
          <li class="list-inline-item"><a href="#" title="Mappa del sito">{{'FOOTER.MAPPA_DEL_SITO' | translate}}</a></li>
          <li class="list-inline-item"><a href="#" title="Accessibilità">{{'FOOTER.ACCESSIBILITA' | translate}}</a></li>
          <li class="list-inline-item"><a href="#" title="Privacy-Cookies">{{'FOOTER.PRIVACY_E_COOKIE' | translate}}</a></li>
          <li class="list-inline-item"><a href="#" title="Note legali">{{'FOOTER.NOTE_LEGALI' | translate}}</a></li>
        </ul>
      </div>
    </div>
  </footer>
</div>
