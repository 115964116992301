<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{'SHOW_MORE_MODAL.CONFRONTA_INFORMAZIONI' | translate}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <svg class="icon">
            <use xlink:href="./assets/svg/sprite.svg#it-close"></use>
        </svg>
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar class="mt-2" [config]="perfectScrollConfig" class="limit-height">
            <div class="col-12" *ngFor="let place of config.places">
                <div class="col" *ngIf="place[config.propName] && place[config.propName] !== null && place[config.propName] !== ''" >
                    <div class="row p-2 m-0 border-bottom-primary align-items-center text-color primary">
                        <span class="romaicon-municipi mr-2 large-icon"></span>
                        <h5>{{getArchive(place.originalDatasourceId).descrizione}}</h5>
                    </div>
                    <div class="row m-0 my-3">
                        <div class="ml-5">
                            <h6 class="text-color primary font-weight-light">{{config.propNameDisplayString}}</h6>
                            <p *ngIf="config.propName !== 'statoDiConservazione'">
                                {{place[config.propName]}}
                            </p>

                            <app-star-rating *ngIf="config.propName === 'statoDiConservazione'" [nStars]="place[config.propName]"></app-star-rating>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('Close click')">{{'SHOW_MORE_MODAL.CHIUDI' | translate}}</button>
        <!-- <button type="button" *ngIf="action" class="btn btn-success" (click)="save()">{{config.actionText}}</button> -->
    </div>
</div>
