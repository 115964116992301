/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/header/header.component.ngfactory";
import * as i3 from "./shared/components/header/header.component";
import * as i4 from "./modules/content/shared/services/toggle-pane.service";
import * as i5 from "./shared/components/navbar/navbar.component.ngfactory";
import * as i6 from "./shared/components/navbar/navbar.component";
import * as i7 from "@angular/router";
import * as i8 from "./core/services/auth.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "angular-oauth2-oidc";
import * as i11 from "./shared/components/spinner/spinner.component.ngfactory";
import * as i12 from "./shared/components/spinner/spinner.component";
import * as i13 from "./shared/services/spinner.service";
import * as i14 from "./shared/components/footer/footer.component.ngfactory";
import * as i15 from "./shared/components/footer/footer.component";
import * as i16 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.TogglePaneService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-navbar", [["class", "sticky-top d-block"]], null, null, null, i5.View_NavbarComponent_0, i5.RenderType_NavbarComponent)), i1.ɵdid(3, 114688, null, 0, i6.NavbarComponent, [i7.Router, i8.IAuthService, i9.TranslateService, i10.OAuthService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["style", "height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-spinner", [], null, null, null, i11.View_SpinnerComponent_0, i11.RenderType_SpinnerComponent)), i1.ɵdid(6, 114688, null, 0, i12.SpinnerComponent, [i13.SpinnerService], null, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-footer", [["class", "mt-auto"], ["style", "width:100%"]], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(10, 114688, null, 0, i15.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i16.AppComponent, [i8.IAuthService, i7.Router, i13.SpinnerService, i9.TranslateService, i10.OAuthService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
