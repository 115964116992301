import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
export class AuthGuard {
    constructor(authService, router, location) {
        this.authService = authService;
        this.router = router;
        this.location = location;
    }
    canLoadOrActivate() {
        return this.authService.checkPostLogin();
    }
    canLoad(route, segments) {
        return this.canLoadOrActivate();
    }
    canActivate(route) {
        return this.canLoadOrActivate();
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.IAuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: AuthGuard, providedIn: "root" });
