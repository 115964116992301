import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowMoreModalConfig } from '../../models/showMoreModalConfig';
import { Archive } from 'src/app/modules/content/shared/models/archive';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-show-more-modal',
  templateUrl: './show-more-modal.component.html',
  styleUrls: ['./show-more-modal.component.scss']
})
export class ShowMoreModalComponent implements OnInit {
  @Input() config:ShowMoreModalConfig
  perfectScrollConfig: PerfectScrollbarConfigInterface = {}
  constructor(public activeModal : NgbActiveModal) { }

  getArchive(id):Archive {
    return this.config.archives.find(a => a.id == id)
  }

  ngOnInit() {
  }

}
