/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-more-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../star-rating/star-rating.component.ngfactory";
import * as i3 from "../star-rating/star-rating.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i7 from "ngx-perfect-scrollbar";
import * as i8 from "./show-more-modal.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_ShowMoreModalComponent = [i0.styles];
var RenderType_ShowMoreModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowMoreModalComponent, data: {} });
export { RenderType_ShowMoreModalComponent as RenderType_ShowMoreModalComponent };
function View_ShowMoreModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit[_co.config.propName]; _ck(_v, 1, 0, currVal_0); }); }
function View_ShowMoreModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-star-rating", [], null, null, null, i2.View_StarRatingComponent_0, i2.RenderType_StarRatingComponent)), i1.ɵdid(1, 114688, null, 0, i3.StarRatingComponent, [], { nStars: [0, "nStars"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit[_co.config.propName]; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ShowMoreModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row p-2 m-0 border-bottom-primary align-items-center text-color primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "romaicon-municipi mr-2 large-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "row m-0 my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "ml-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h6", [["class", "text-color primary font-weight-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowMoreModalComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowMoreModalComponent_4)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.config.propName !== "statoDiConservazione"); _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.config.propName === "statoDiConservazione"); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getArchive(_v.parent.context.$implicit.originalDatasourceId).descrizione; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.config.propNameDisplayString; _ck(_v, 8, 0, currVal_1); }); }
function View_ShowMoreModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowMoreModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit[_co.config.propName] && (_v.context.$implicit[_co.config.propName] !== null)) && (_v.context.$implicit[_co.config.propName] !== "")); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShowMoreModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/sprite.svg#it-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "perfect-scrollbar", [["class", "limit-height"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i6.View_PerfectScrollbarComponent_0, i6.RenderType_PerfectScrollbarComponent)), i1.ɵdid(10, 507904, null, 0, i7.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ShowMoreModalComponent_1)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.perfectScrollConfig; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.config.places; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("SHOW_MORE_MODAL.CONFRONTA_INFORMAZIONI")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 10).autoPropagation; var currVal_2 = i1.ɵnov(_v, 10).scrollIndicators; _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("SHOW_MORE_MODAL.CHIUDI")); _ck(_v, 15, 0, currVal_5); }); }
export function View_ShowMoreModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-more-modal", [], null, null, null, View_ShowMoreModalComponent_0, RenderType_ShowMoreModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ShowMoreModalComponent, [i9.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowMoreModalComponentNgFactory = i1.ɵccf("app-show-more-modal", i8.ShowMoreModalComponent, View_ShowMoreModalComponent_Host_0, { config: "config" }, {}, []);
export { ShowMoreModalComponentNgFactory as ShowMoreModalComponentNgFactory };
