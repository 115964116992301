import { finalize, timeout, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "angular-oauth2-oidc";
import * as i3 from "../../core/services/impl/auth.impl.service";
import * as i4 from "./spinner.service";
export class MyHttpInterceptor {
    constructor(translateService, oauthService, authService, spinner) {
        this.translateService = translateService;
        this.oauthService = oauthService;
        this.authService = authService;
        this.spinner = spinner;
        this.count = 0;
    }
    handleAuthError(err) {
        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            this.oauthService.logOut();
            this.authService.logout();
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }
    intercept(req, next) {
        if (req.url.startsWith("assets") || req.url.startsWith(environment.sso.baseSSOUri)) {
            return next.handle(req);
        }
        this.spinner.show();
        this.count++;
        const langRequest = req.clone({
            body: Object.assign({}, req.body, { lang: this.translateService.currentLang })
        });
        return next.handle(langRequest)
            .pipe(catchError(err => this.handleAuthError(err)), timeout(environment.httpReqTimeout), finalize(() => {
            this.count--;
            if (this.count == 0) {
                this.spinner.hide();
            }
        }));
    }
}
MyHttpInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyHttpInterceptor_Factory() { return new MyHttpInterceptor(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.OAuthService), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.SpinnerService)); }, token: MyHttpInterceptor, providedIn: "root" });
