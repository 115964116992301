import { Component, OnInit } from '@angular/core';
import { TogglePaneService } from 'src/app/modules/content/shared/services/toggle-pane.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public show = true;
  constructor(private togglePaneService: TogglePaneService) {
    togglePaneService.isOpen.subscribe(isOpen => isOpen? this.show = true : this.show = false)
  }

  ngOnInit() {
  }

}
