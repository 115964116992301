import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Place } from '../models/place';
import { Filter } from '../models/filters';
import { Category } from 'src/app/modules/content/shared/models/category';
import { MapMarker } from '../models/mapMarker';
import { StringSearchResponseDTO } from '../models/stringSearchResponseDTO';
import { GeoGruppo } from '../models/geoGruppo';
import { Metadati } from '../models/metadati';

@Injectable({
  providedIn: 'root'
})
export abstract class IPlacesService {
  abstract reset()
  abstract getPlaces():Observable<Metadati[]> | Observable<StringSearchResponseDTO[]>
  // abstract getMarkers():Observable<MapMarker[]> | Observable<GeoGruppo>
  abstract getMarkers():Observable<MapMarker[]>
  abstract setMarkers(metadati: Metadati[]):void
  abstract filterPlaces(filter: Filter):Observable<Metadati[]> | Observable<StringSearchResponseDTO[]>
  abstract getCategories():Observable<Category[]>
  abstract setPlaces(places: Metadati[])
}
