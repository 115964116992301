<div class="rating color-positive " [ngClass]="ratingClass">
    <div class="star-container p-0 m-0">
        <div class="star">
            <svg class="star-empty">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-outline"></use>
            </svg>
            <svg class="star-filled">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-full"></use>
            </svg>                                        
        </div>
        <div class="star">
            <svg class="star-empty">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-outline"></use>
            </svg>
            <svg class="star-filled">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-full"></use>
            </svg>                                        
        </div>
        <div class="star">
            <svg class="star-empty">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-outline"></use>
            </svg>
            <svg class="star-filled">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-full"></use>
            </svg>                                        
        </div>
        <div class="star">
            <svg class="star-empty">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-outline"></use>
            </svg>
            <svg class="star-filled">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-full"></use>
            </svg>                                        
        </div>
        <div class="star">
            <svg class="star-empty">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-outline"></use>
            </svg>
            <svg class="star-filled">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/svg/sprite.svg#it-star-full"></use>
            </svg>                                        
        </div>
     </div>
</div>