import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomePageComponent } from './modules/content/components/pages/home-page/home-page.component';
import { LogoutPageComponent } from './shared/components/pages/logout-page/logout-page.component';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule)},
  { path: 'content', loadChildren: () => import('./modules/content/content.module').then(mod => mod.ContentModule), canLoad: [AuthGuard], canActivate: [AuthGuard]},
  { path: 'logout', canActivate: [AuthGuard], component: LogoutPageComponent},
  { path: '', redirectTo: 'content/home', pathMatch: 'full'  },
  { path: '**', redirectTo: '/content/home', pathMatch: 'full' }
];

// const routes: Routes = []
@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
