import { finalize, tap, timeout, catchError } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/impl/auth.impl.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class MyHttpInterceptor implements HttpInterceptor {
  count = 0;

  constructor(private readonly translateService: TranslateService,private readonly oauthService: OAuthService,private readonly authService: AuthService, private readonly spinner: SpinnerService) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
        //navigate /delete cookies or whatever
        this.oauthService.logOut();
        this.authService.logout();
        // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
        return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    if (req.url.startsWith("assets") || req.url.startsWith(environment.sso.baseSSOUri)) {
      return next.handle(req);
    }

    this.spinner.show()
    this.count++;


    const langRequest = req.clone({
      body: {
        ...req.body,
        lang: this.translateService.currentLang
      }
    });

    return next.handle(langRequest)
      .pipe(
        catchError(err => this.handleAuthError(err)),
        timeout(environment.httpReqTimeout), finalize(() => {
        this.count--;
        if (this.count == 0) {
          this.spinner.hide();
        }
      })
      );
  }
}
