<div class="row m-0" style="width: 100%" *ngIf="show">
  <div class="it-nav-wrapper" style="width:100%">
      <div class="it-header-center-wrapper border-white border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="it-header-center-content-wrapper" style="flex-direction: row;">
                <div class="it-brand-wrapper">
                  <a [routerLink]="['content/home']">
                    <img src="assets/img/logo_roma.png" class="image-responsive-width">
                  </a>
                </div>
                <div class="it-brand-text">
                  <div class="title">FORMA ROMÆ</div>
                </div>
                <div class="it-right-zone" style="max-width: 30%;">
                  <div class="it-search-wrapper">
                    <img src="assets/img/logo_ponMetro.png" style="width: 85%; height: 85%; object-fit: scale-down">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>