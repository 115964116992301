import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '../../models/modalConfig';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() config:ModalConfig
  @Output() action: Function
  defaultCloseText: string = "Chiudi"
  optionsForm: FormGroup;
  dimensions = {
    A0: [1189, 841],
    A1: [841, 594],
    A2: [594, 420],
    A3: [420, 297],
    A4: [297, 210],
    A5: [210, 148]
  };
  resolutions = [
    72,
    150,
    300
  ]
  selectedDim:any = {}
  selectedRes:any

  constructor(public activeModal : NgbActiveModal, private formBuilder: FormBuilder,){
    // // console.log("key:",Object.keys(this.dimensions)[4])
    this.optionsForm = this.formBuilder.group({
      dimensionsSelect: Object.keys(this.dimensions)[4],
      resolutionSelect: this.resolutions[0]
    });
    this.selectedDim.size = this.dimensions.A4
    this.selectedDim.format = 'a4'
    this.selectedRes = this.resolutions[0]
    // // console.log('selecteddim',this.selectedDim,'selectedRes',this.selectedRes,this.dimensions,this.resolutions)
  }

  onDimensionChange() {
    this.selectedDim.size = this.dimensions[this.optionsForm.controls['dimensionsSelect'].value]
    this.selectedDim.format = (this.optionsForm.controls['dimensionsSelect'].value as string).toLowerCase()
  }
  onResolutionChange(){
    this.selectedRes = this.optionsForm.controls['resolutionSelect'].value
  }
  save() {
    this.action(this.selectedDim,this.selectedRes);
    this.activeModal.close('Close click');
  }
  
  ngOnInit(){
  }

}