import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Input('nStars') nStars:number;
  ratingClass: string = 'value-'
  constructor() {}

  ngOnInit() {
    this.ratingClass = this.ratingClass + this.nStars;
    // // console.info('[StarRatingComponent] setting star rating class to: ',this.ratingClass)
  }

}
