import * as tslib_1 from "tslib";
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { FilterType, GeometryType } from '../../models/filters';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, retry, map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../core/services/auth.service";
export class PlacesService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.markers$ = new BehaviorSubject(undefined);
        this.markersObservable = this.markers$.asObservable();
        this.places$ = new BehaviorSubject(undefined);
        this.placesObservable = this.places$.asObservable();
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': environment.api.contentType
            })
        };
        this.authService.getUser().subscribe(user => {
            user ? this.user = user : this.user = undefined;
            // // console.info('[PlacesService] user is', this.user)
        });
    }
    setPlaces(places) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.places$.next(places);
            this.markers$.next(yield this.mapPlacesToMarkers(places));
        });
    }
    setMarkers(metadati) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.markers$.next(undefined);
            this.markers$.next(yield this.mapPlacesToMarkers(metadati));
        });
    }
    getMarkers() {
        return this.markersObservable;
    }
    getPlaces() {
        return this.placesObservable;
    }
    filterPlaces(filter) {
        let q;
        switch (filter.by) {
            case (FilterType.id):
                q = filter.query;
                const findByIdDTO = {
                    user: this.user.username,
                    userRole: this.user.role,
                    idGroup: q.id,
                    originalDatasourceId: q.originalDatasourceId
                };
                return this.http.post(environment.api.geoSearch.detail.endpoint, findByIdDTO, this.httpOptions)
                    .pipe(retry(3), catchError(this.handleError), map(metadati => metadati.map(m => (Object.assign({ id: q.id }, m, { localizzazione: this.parseLocalizzazione(m.localizzazione), datazioneEsatta: this.getDatazione(m), immaginePertinente: m.immaginePertinente ? m.immaginePertinente /*m.immaginePertinente.map(i => this.parseArraySyntax(i))[0]*/ : [], immagineConnessa: m.immagineConnessa ? m.immagineConnessa /*m.immagineConnessa.map(i => this.parseArraySyntax(i))[0]*/ : [] //this.parseArraySyntax(m.immagineConnessa[0]),
                 })))), 
                //hotfix per visualizzare geometria vincitore...
                //possibili problemi se vogliono visualizzare tutte le geom in futuro,
                //filtare onlre che per id geogruppo anche per resource id.
                //prendere res id originaria usare quella per settare elemento 0 array markers
                tap((m) => tslib_1.__awaiter(this, void 0, void 0, function* () { return this.markers$.next(yield this.mapPlacesToMarkers([m[0]])); })));
            case (FilterType.string):
                q = filter.query;
                const stringSearchDTO = {
                    user: this.user.username,
                    user_role: this.user.role,
                    input: q.input
                };
                let endpoint = environment.api.textSearch.basic.endpoint;
                if (q.filters) {
                    //advanced search
                    stringSearchDTO.filters = q.filters;
                    endpoint = environment.api.textSearch.advanced.endpoint;
                    // // console.info('[PlacesService] andvancedStringSearchDTO', stringSearchDTO)
                }
                return this.http.post(endpoint, stringSearchDTO, this.httpOptions)
                    .pipe(retry(3), catchError(this.handleError), tap(_ => {
                    this.markers$.next(_.map(e => ({
                        id: e.geoGruppo.id,
                        geolocalizzazione: e.geoGruppo.geolocalizzazione,
                        nome: e.geoGruppo.nome,
                        nomeProprio: e.metadati[0].nomeProprio,
                        resourceId: e.metadati[0].resourceId,
                        originalDatasourceId: e.metadati[0].originalDatasourceId,
                        individuazioneTipologica: e.metadati[0].individuazioneTipologica,
                        tipologiaLocalizzazione: e.metadati[0].tipologiaLocalizzazione
                    })));
                }), 
                // THIS IS THE RETURNED OBSERVABLE DATA!
                map(res => res.map(e => {
                    return Object.assign({ id: e.geoGruppo.id, numeroArchivi: e.geoGruppo.numeroArchivi, miniatura: e.geoGruppo.miniatura }, e.metadati[0] || null, { localizzazione: this.parseLocalizzazione(e.metadati[0].localizzazione), datazioneEsatta: this.getDatazione(e.metadati[0]), metadati: e.metadati });
                })), map(metadati => metadati.sort((a, b) => (b.pesi.pesoDeduplica - a.pesi.pesoDeduplica))), //peso deduplica decrescente
                tap(metadati => metadati[0] ? metadati[0].highlight = true : null), tap(_ => {
                    // console.log('[PlacesService][StringSearch] metadati:',_)
                    this.places$.next(_);
                }));
            case (FilterType.location):
                q = filter.query;
                if (q.type === GeometryType.circle) {
                    const circleQuery = filter.query;
                    const circleFilterDTO = {
                        user: this.user.username,
                        user_role: this.user.role,
                        radius: circleQuery.radius,
                        input: {
                            type: circleQuery.type,
                            coordinates: circleQuery.coordinates
                        }
                    };
                    return this.http.post(environment.api.geoSearch.circle.endpoint, circleFilterDTO, this.httpOptions)
                        .pipe(retry(3), catchError(this.handleError), map(metadati => metadati.map(m => (Object.assign({ id: m.resourceId }, m, { localizzazione: this.parseLocalizzazione(m.localizzazione), datazioneEsatta: this.getDatazione(m) })))), tap((m) => tslib_1.__awaiter(this, void 0, void 0, function* () { return this.markers$.next(yield this.mapPlacesToMarkers(m)); })), map(metadati => metadati.sort((a, b) => (a.nomeProprio.localeCompare(b.nomeProprio)))));
                }
                else {
                    const polygonQuery = filter.query;
                    const polygonFilterDTO = {
                        user: this.user.username,
                        user_role: this.user.role,
                        input: {
                            type: polygonQuery.type,
                            coordinates: polygonQuery.coordinates
                        }
                    };
                    return this.http.post(environment.api.geoSearch.polygon.endpoint, polygonFilterDTO, this.httpOptions)
                        .pipe(retry(3), catchError(this.handleError), map(metadati => metadati.map(m => (Object.assign({ id: m.resourceId }, m, { localizzazione: this.parseLocalizzazione(m.localizzazione), datazioneEsatta: this.getDatazione(m) })))), tap((m) => tslib_1.__awaiter(this, void 0, void 0, function* () { return this.markers$.next(yield this.mapPlacesToMarkers(m)); })), map(metadati => metadati.sort((a, b) => (a.nomeProprio.localeCompare(b.nomeProprio)))));
                }
            case FilterType.image:
                // console.log('[PlacesService] Filtering by image')
                q = filter.query;
                const imageDto = {
                    user: this.user.username,
                    role: this.user.role,
                    input: q.imagePath
                };
                return this.http.post(environment.api.imageSearch.endpoint, imageDto, this.httpOptions)
                    .pipe(retry(3), catchError(this.handleError), map(groups => groups.map(e => (Object.assign({ id: e.geoGruppo.id, numeroArchivi: e.geoGruppo.numeroArchivi, miniatura: e.geoGruppo.miniatura, localizzazione: this.parseLocalizzazione(e.metadati[0].localizzazione), datazioneEsatta: this.getDatazione(e.metadati[0]), immaginePertinente: e.metadati[0].immaginePertinente, immagineConnessa: e.metadati[0].immagineConnessa }, e.metadati[0])))), map(metadati => metadati.sort((a, b) => (b.pesi.pesoDeduplica - a.pesi.pesoDeduplica))), tap((_) => tslib_1.__awaiter(this, void 0, void 0, function* () { this.markers$.next(yield this.mapPlacesToMarkers(_)); })));
            case FilterType.selection:
                // // console.log('[placesService] filter by selection: ',this.places$.value)
                // this.places$.next(this.places$.value)
                return this.placesObservable;
        }
    }
    getCategories() {
        let dto = {
            user: this.user.username,
            role: this.user.role
        };
        // return of(this.sampleCategories)
        return this.http.post(environment.api.categories.endpoint, dto, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getNewDate(s) {
        const d = new Date(s).toLocaleDateString('it-IT', { year: 'numeric' });
        if (d !== 'Invalid Date')
            return d;
        else
            return 'Non disponibile';
    }
    getDatazione(m) {
        let dateString = "";
        if (m.datazioneEsatta)
            dateString = "Datazione esatta: " + this.getNewDate(m.datazioneEsatta);
        if (m.datazionePeriodo)
            dateString = "Periodo: " + this.getNewDate(m.datazionePeriodo);
        if (m.datazioneDa && m.datazioneA)
            dateString = "Periodo, da " + this.getNewDate(m.datazioneDa) + " A " + this.getNewDate(m.datazioneA);
        // // console.log("[PlacesService] getDatazione returning:",dateString,"for place: ",m)
        return dateString;
    }
    mapPlacesToMarkers(places) {
        const pLength = places.length;
        let markers = [];
        let count = 0;
        return new Promise((resolve, reject) => {
            return places.forEach(e => {
                count++;
                // // console.log("PROCESSING MARKER",count)
                // // console.log('[PlacesService] mapping place',e,'to marker')
                markers.push({
                    id: e.id ? e.id : e.resourceId,
                    nomeProprio: e.nomeProprio,
                    nome: e.nomeProprio,
                    resourceId: e.resourceId,
                    dbName: e.originalDatasourceId,
                    geolocalizzazione: e.geolocalizzazione,
                    originalDatasourceId: e.originalDatasourceId,
                    individuazioneTipologica: e.individuazioneTipologica,
                    tipologiaLocalizzazione: e.tipologiaLocalizzazione
                });
                if (count == pLength)
                    resolve(markers);
            });
        });
    }
    parseLocalizzazione(s) {
        if (s !== null && s !== undefined && s !== "") {
            if (s.charAt(0) === '{') {
                // return s.substring(2, s.substring(2).indexOf(',') + 1).trim()
                return s.substring(1, s.length - 1).trim().split(',')[0];
            }
            return s.trim();
        }
        return s;
    }
    // parseArraySyntax(s: string) {
    //     // // console.log('[PlacesService][ParseArraySyntax] input string',s)
    //     if (s !== null && s !== undefined && s !== "") {
    //         if (s.charAt(0) === '{') {
    //             let retArray = s.substring(1, s.length-1).trim().split(',')
    //             // // console.log('[placesService][ParseArraySyntax] return array',retArray)
    //             return retArray
    //         }
    //         return [s.trim()]
    //     }
    //     return [s]
    // }
    parseArraySyntax(s) {
        // console.log('[PlacesService][ParseArraySyntax] input string', s)
        if (s !== null && s !== undefined && s !== "") {
            if (s.charAt(0) === '{') {
                // let retArray = s.substring(1, s.length-1).trim().split(',')
                let retArray = s.substring(1, s.length - 1).trim().replace(/\"/g, "").replace(/ /g, '').split(',');
                // // console.log('[placesService][ParseArraySyntax] return array before',retArray)
                // retArray.forEach(s => {s = s.trim().substring(0, s.length -2).trim(); // console.log('parsed string',s)})
                // // console.log('[placesService][ParseArraySyntax] return array',retArray)
                return retArray;
            }
            return [s.trim()];
        }
        return [s];
    }
    reset() {
        this.markers$.next(undefined);
        this.places$.next(undefined);
        // // console.log('[PlacesService] reset was called markers',this.markers$.value,'places',this.places$.value)
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error('Backend returned code:', error.status, 'body was:', error.error);
            console.error(error);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
    ;
}
PlacesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlacesService_Factory() { return new PlacesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.IAuthService)); }, token: PlacesService, providedIn: "root" });
