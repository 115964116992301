import { Component } from '@angular/core';
import { IAuthService } from './core/services/auth.service';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SpinnerService } from './shared/services/spinner.service';
import { BehaviorSubject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = environment.sso.config;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FormaRomae';
  config: PerfectScrollbarConfigInterface = {}
  isLoading: BehaviorSubject<boolean> = this.spinnerService.isVisible();


  constructor(private authService: IAuthService, private router: Router, private spinnerService: SpinnerService,
    private translate: TranslateService, private oauthService: OAuthService) {
      this.authService.login().subscribe(() => {
        this.authService.checkPostLogin()
       });

    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      this.translate.use(browserLang.match(/it|en/) ? browserLang : 'it');
    } else {
      localStorage.setItem('locale', 'it');
      this.translate.use('it');
    }

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.events.subscribe(x => {

      if(x.type == 'token_received' || x.type == 'discovery_document_loaded'){
        this.authService.login().subscribe(() => {
          this.authService.checkPostLogin()
         });
      }
    })
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(x => {
      this.authService.login().subscribe(() => {
        this.authService.checkPostLogin()
       });
    });
  }
}
