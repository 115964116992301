import { Component, OnInit } from '@angular/core';
import { IAuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(private authService: IAuthService, private router: Router) {
  }

  logout() {
    // console.info('[LogoutComponent] logging user out')
    this.authService.logout()
  }
  ngOnInit() {
  }

}
