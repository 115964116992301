import { AdvancedSearchFilter } from './advancedSearchfilters';

export enum FilterType {
    id,
    string,
    categoryId,
    location,
    image,
    selection
}

export interface ImageQuery {
    imagePath:string
}

export enum GeometryType {
    circle = 'Point',
    polygon = 'Polygon'
}

export interface GeometryQuery {
    type: GeometryType
}

export interface PolygonQuery extends GeometryQuery {
    coordinates: any
}

export interface CircleQuery extends GeometryQuery {
    radius: number,
    coordinates: any[]
}

export interface IdQuery {
    resourceId?: string,
    originalDatasourceId?: string,
    id: string
}

export interface StringQuery {
    input: string,
    filters: AdvancedSearchFilter
}

export interface Filter {
    query?: GeometryQuery | StringQuery | IdQuery | ImageQuery
    by?: FilterType
}