import { Injectable } from "@angular/core";
import { CanLoad, Route, UrlSegment, Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { IAuthService } from '../services/auth.service';
import { Location } from '@angular/common';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanLoad, CanActivate {
  
  constructor(private authService: IAuthService, private router: Router,private location: Location) {}

  private canLoadOrActivate(): boolean {
    return this.authService.checkPostLogin();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.canLoadOrActivate();
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canLoadOrActivate();
  }
  
}