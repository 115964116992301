import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SpinnerService {
    constructor() {
        this.visible$ = new BehaviorSubject(false);
    }
    show() {
        // // console.log('[SpinnerService] Showing Spinner')
        this.visible$.next(true);
    }
    hide() {
        // // console.log('[SpinnerService] Hiding Spinner')
        this.visible$.next(false);
    }
    isVisible() {
        return this.visible$;
    }
}
SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
