import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  isVisible: BehaviorSubject<boolean> = this.spinnerService.isVisible();
  constructor(private spinnerService: SpinnerService) { }

  ngOnInit() {
  }

}
