<div class="it-header-navbar-wrapper" style="width:100%; z-index: 1050;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-sm">
                    <button class="custom-navbar-toggler" type="button" aria-controls="nav10" aria-expanded="false"
                        aria-label="Toggle navigation" data-target="#nav10">
                        <svg class="icon">
                            <use xlink:href="./assets/svg/sprite.svg#it-burger"></use>
                        </svg>
                    </button>
                    <div class="navbar-collapsable" id="nav10">
                        <div class="overlay"></div>
                        <div class="close-div sr-only">
                            <button class="btn close-menu" type="button">
                                <span class="it-close"></span>
                                close
                            </button>
                        </div>
                        <div class="menu-wrapper">
                            <ul class="navbar-nav">
                                <li [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.home)}">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.home)}"
                                        [routerLink]="[ '/content/home']">
                                        <span>{{'NAVBAR.HOME' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.home)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.project)}">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.project)}"
                                        [routerLink]="[ '/content/project']">
                                        <span>{{'NAVBAR.IL_PROGETTO' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.project)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.archives) }">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.archives)}"
                                        [routerLink]="[ '/content/archives']">
                                        <span>{{'NAVBAR.GLI_ARCHIVI' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.archives)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li *ngIf="isLoggedIn()" [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.gamification)}">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.gamification)}"
                                        [href]="gamificationURL" target="_blank">
                                        <span>{{'NAVBAR.GAMIFICATION' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.project)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li *ngIf="!isLoggedIn()" [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.gamification)}">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.gamification)}"
                                        [href]="gamificationPublicURL" target="_blank">
                                        <span>{{'NAVBAR.GAMIFICATION' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.project)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.pointCloud)}">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.pointCloud)}"
                                        [routerLink]="[ '/content/pointClouds']">
                                        <span>{{'NAVBAR.ARCHIVIO_3D' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.project)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li *ngIf="isLoggedIn()" [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.apiCatalog)}">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.apiCatalog)}"
                                        [href]="apiCatalogURL" target="_blank">
                                        <span>{{'NAVBAR.DEVELOPERS' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.project)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li *ngIf="!isLoggedIn()" [ngClass]="{'nav-item': true, 'active': currentURL.includes(urls.apiCatalog)}">
                                    <a [ngClass]="{'nav-link': true, 'active': currentURL.includes(urls.apiCatalog)}"
                                        [href]="apiCatalogPublicURL" target="_blank">
                                        <span>{{'NAVBAR.DEVELOPERS' | translate}}</span>
                                        <span *ngIf="currentURL.includes(urls.project)" class="sr-only">current</span>
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="!isLoggedIn()">
                                    <a class="nav-link secondaryColor area-riservata" (click)="login()">
                                        <span>{{'NAVBAR.ACCEDI' | translate}}</span>
                                        <i class="romaicon-area-riservata ml-3"></i>
                                        
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="isLoggedIn()">
                                    <a class="nav-link secondaryColor area-riservata area-riservata-no-hover">
                                        <span>{{user.username}}</span>
                                        <!-- <i class="romaicon-area-riservata ml-3"></i>s -->
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="isLoggedIn()">
                                    <a class="nav-link secondaryColor area-riservata" (click)="logout()">
                                        <span>{{'NAVBAR.LOGOUT' | translate}}</span>
                                    </a>
                                </li>
                              <li class="nav-item">
                                <a class="nav-link">
                                  <ng-container [ngSwitch]="currentLang">
                    <span *ngSwitchCase="'en'" (click)="setLanguage('it')">
                      <img class="language" src="./assets/img/it.png" alt="it">
                    </span>
                                    <span *ngSwitchCase="'it'" (click)="setLanguage('en')">
                      <img class="language" src="./assets/img/uk.png" alt="uk">
                    </span>
                                  </ng-container>
                                </a>
                              </li>


                            </ul>
                            <!-- <div class="it-header-slim-right-zone pb-2">
                                <ul class="navbar-nav">
                                    <li class="nav-item" *ngIf="!authenticated">
                                        <a class="nav-link py-0 secondaryColor area-riservata" routerLinkActive="active" [routerLink]="['auth/login']">
                                            Area Riservata
                                            <span class="romaicon-area-riservata ml-3 pb-2"></span>
                                            <span *ngIf="currentURL === urls.login" class="sr-only">current</span>
                                        </a>
                                    </li>

                                    <li class="nav-item" *ngIf="authenticated">
                                        <a class="nav-link py-0 secondaryColor area-riservata" [ngClass]="{'active': currentURL === urls.logout}" (click)="logout()">
                                            Logout
                                            <span class="romaicon-start ml-3 pb-2"></span>
                                            <span *ngIf="currentURL === urls.login" class="sr-only">current</span>
                                        </a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>
