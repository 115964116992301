/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../logout/logout.component.ngfactory";
import * as i3 from "../../logout/logout.component";
import * as i4 from "../../../../core/services/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "./logout-page.component";
var styles_LogoutPageComponent = [i0.styles];
var RenderType_LogoutPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutPageComponent, data: {} });
export { RenderType_LogoutPageComponent as RenderType_LogoutPageComponent };
export function View_LogoutPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, i2.View_LogoutComponent_0, i2.RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.LogoutComponent, [i4.IAuthService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LogoutPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout-page", [], null, null, null, View_LogoutPageComponent_0, RenderType_LogoutPageComponent)), i1.ɵdid(1, 114688, null, 0, i6.LogoutPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutPageComponentNgFactory = i1.ɵccf("app-logout-page", i6.LogoutPageComponent, View_LogoutPageComponent_Host_0, {}, {}, []);
export { LogoutPageComponentNgFactory as LogoutPageComponentNgFactory };
